import React from 'react';
import Img from 'gatsby-image';
import cn from 'classnames';

import { Icon } from '../icons';
import Price from './Price';

import { getProductUrl, ShopifyFluid, getLocaleSettings } from '../../utils';

import { trackProductClick } from '../../utils/gtm';

import trads from './trads';

import {
  Wrapper,
  Cover,
  Text,
  // OutOfStock,
  EshopInfos,
} from './ProductItem.styles';

import GreenCheckMark from '../../icons/GreenCheckMark.svg';
import { useLocale } from '../../services/i18n/useLocale';

const imgResponsive = '(max-width: 1024px) 40vw, (max-width: 1440px) 20vw, 300px';

const doTrack = async (props) => {
  trackProductClick({
    name: props.title,
    sku: props.defaultVariant?.sku,
    price: props.defaultVariant?.price,
    category: props.productType,
    list: props.trackList,
    variant: props.defaultVariant?.title,
    position: props.trackPosition,
  });
};

const ProductItem = (props) => {
  const {
    title,
    productType,
    thumbnail,
    defaultVariant,
    handle,
    urlParams,
    priceRange,
    className,
    availableForSale = false,
    noSEO = false,
    displayFrom = false,
    largeDisplay = false,
    imageLoading = 'lazy',
    showStock = false,
    customSizes = imgResponsive,
  } = props;
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);
  const imageUrl = thumbnail?.originalSrc;
  const imageInfos = thumbnail?.localThumb?.childImageSharp?.fixed;
  const imageFixed = ShopifyFluid(
    imageUrl,
    { maxWidth: largeDisplay ? 500 : 300 },
    {
      ...imageInfos,
      aspectRatio: imageInfos ? imageInfos?.width / imageInfos?.height : 1,
      width: undefined,
      height: undefined,
    }
  );
  imageFixed.sizes = customSizes;

  let url = getProductUrl(handle, intl.locale, defaultVariant);

  if (urlParams) {
    const params = Object.keys(urlParams).map(
      (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
    );
    url = `${url}?${params.join('&')}`;
  }
  let price = defaultVariant?.price;
  let originalPrice = defaultVariant?.compareAtPrice;

  if (displayFrom && priceRange?.minVariantPrice?.amount) {
    price = originalPrice = priceRange?.minVariantPrice?.amount;
  }

  return (
    <Wrapper
      to={url}
      onClick={() => {
        doTrack(props);
      }}
      className={cn(className, {
        'price-left': largeDisplay && !showStock,
      })}
    >
      <Cover>
        {imageFixed ? (
          <Img
            fluid={imageFixed}
            alt={thumbnail?.altText || title}
            loading={imageLoading}
            fadeIn={imageLoading !== 'eager'}
            className="product-image"
          />
        ) : (
          <div className="gatsby-image-wrapper" />
        )}
      </Cover>
      {/* {!availableForSale ? (
        <OutOfStock>{intl.formatMessage(trads.outOfStock)}</OutOfStock>
      ) : null} */}
      <Text>
        {noSEO ? (
          <div className="product-title">{title}</div>
        ) : (
          <h3 className="product-title">{title}</h3>
        )}
        <div className="category">{productType}</div>
        {settings.shop ? (
          <EshopInfos>
            {!!showStock && !!availableForSale && !displayFrom && (
              <div className="stock">
                <Icon src={GreenCheckMark} height={10} width={12} />
                {intl.formatMessage(trads.inStock)}
              </div>
            )}
            <Price
              price={price}
              originalPrice={originalPrice}
              displayFrom={displayFrom}
            />
          </EshopInfos>
        ) : null}
      </Text>
    </Wrapper>
  );
};

export default ProductItem;
