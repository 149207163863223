import styled from 'styled-components';

import {
  device
} from '../../utils';

export const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media ${device.tablet} {
    grid-column: auto;
  }

  button {
    border: 0;
    outline: none;
    appearance: none;
    background-color: #EFEFEF;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    transition: background-color 200ms;

    &[disabled] {
      cursor: default;
    }

    &:not([disabled]):hover {
      background-color: #ededed;
    }

    &:active {
      background-color: #dbdbdb;
    }
  }

  span {
    min-width: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }

  &.small {
    button {
      width: 30px;
      height: 30px;
      font-size: 16px;
    }

    span {
      font-size: 13px;
      min-width: 30px;
    }
  }
`;