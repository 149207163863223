import React, { useState, useCallback, useEffect } from 'react';

import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';

import { useWindowSize } from 'react-use';

import useShopify from '../../services/shopify';

import { Icon } from '../icons';
import CloseIcon from '../../icons/Close.svg';
import SecureIcon from '../../icons/Secure.svg';

import CartItem from './CartItem';
import ShippingStatus from '../eshop/ShippingStatus';

import { trackRemoveFromCart, trackAddToCart } from '../../utils/gtm';
import ProductItem from '../eshop/ProductItem';

import Price from '../eshop/Price';

import { Wrapper, Cart, LoaderWrapper } from './CartPanel.styles';
import trads from './trads';
import CartProgress from './CartProgress';
import { useLocale } from '../../services/i18n/useLocale';
import CartNewsletter from './CartNewsletter';
import { getLocaleSettings } from '../../utils';
import CrossSell from './CrossSell';

const Loader = () => (
  <LoaderWrapper>
    <div />
    <div />
    <div />
    <div />
  </LoaderWrapper>
);

const renderRecapLine = (label, value, bigValue = true) => (
  <div className="item">
    <div className="label">{label}</div>
    <div className={`value ${bigValue ? 'big' : ''}`}>
      <Price price={value} />
    </div>
  </div>
);

const trackStats = (add = true, line) => {
  const method = add ? trackAddToCart : trackRemoveFromCart;
  method({
    name: line.title,
    quantity: line.quantity,
    price: line.variant?.price,
    variant: line?.variant?.title,
    sku: line?.variant?.sku,
  });
};

const CartPanel = ({
  open,
  onClose,
  showAdded = false,
  loading = false,
  options,
}) => {
  const intl = useLocale();
  const localeSettings = getLocaleSettings(intl.locale);
  const [mounted, setMounted] = useState(false);
  const isOpen = mounted && open;

  const {
    checkout,
    cartItems,
    lastAddedItem,
    removeLineItem: apiRemoveLineItem,
    updateLineItem: apiUpdateLineItem,
    addToCart: apiAddToCart
  } = useShopify();

  const items = cartItems || [];
  const quantity = items.reduce((acc, item) => acc + item.quantity, 0);
  const { width, height } = useWindowSize();
  const size = Math.round((Math.min(width, 468) / width) * 100);

  useEffect(() => {
    // Wait for first render before animating
    setMounted(true);

    return () => {
      window.document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const removeLineItem = useCallback(
    (line) => {
      apiRemoveLineItem(line);
      trackStats(false, line);
    },
    [apiRemoveLineItem]
  );

  const updateLineItem = useCallback(
    (line, quantity) => {
      apiUpdateLineItem(line.id, quantity);
      trackStats(quantity > line.quantity, line);
    },
    [apiUpdateLineItem]
  );

  const itemSkus = items?.reduce((result, item) => [...result, item?.variant?.sku], [])?.filter(sku => !!sku);

  return (
    <Wrapper>
      <SlidingPanel
        type={'right'}
        isOpen={mounted && open}
        size={size}
        backdropClicked={onClose}
      >
        <Cart style={{ height }}>
          <div className="header">
            <h3>
              <strong>{intl.formatMessage(trads.panelTitle)}</strong>
              <span>
                {quantity === 1 &&
                  `(${intl.formatMessage(trads.itemsCount, {
                    quantity,
                  })})`}
                {quantity > 1 &&
                  `(${intl.formatMessage(trads.itemsCountPlural, {
                    quantity,
                  })})`}
              </span>
              {loading ? <Loader /> : null}
            </h3>

            <button className="clear" onClick={onClose}>
              <Icon src={CloseIcon} width={20} height={20} />
            </button>
          </div>

          {!!checkout.totalPriceV2 && (
            <CartProgress
              stepPrice={options?.incentiveAmount}
              currentPrice={parseFloat(checkout.totalPriceV2?.amount, 10)}
              incentiveText={options?.incentiveInprogress}
              congratsText={options?.incentiveEnd}
              currency={checkout?.totalPriceV2?.currencyCode}
              incentiveSteps={options?.incentiveSteps}
            />
          )}

          <div className="items">
            {items.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                removeLineItem={removeLineItem}
                updateLineItem={updateLineItem}
                loading={loading}
                showAdded={showAdded && item?.id === lastAddedItem?.id}
              />
            ))}
          </div>
          {quantity > 0 ? (
            <>
              <div className="recap">
                {renderRecapLine(
                  intl.formatMessage(trads.total),
                  checkout.subtotalPrice
                )}
              </div>
              <CartNewsletter
                currentPrice={parseFloat(checkout.subtotalPriceV2?.amount, 10)}
                currency={checkout?.subtotalPriceV2?.currencyCode}
              />
              <a
                className="button validate dark"
                href={`${checkout.webUrl}&locale=${localeSettings.cmsLocale}-${localeSettings.locale}`}
              >
                {intl.formatMessage(trads.orderNow)}
              </a>
              <div className="secure">
                <Icon src={SecureIcon} width={12} />
                <span>{intl.formatMessage(trads.secure)}</span>
              </div>
            </>
          ) : (
            <div className="empty">{intl.formatMessage(trads.empty)}</div>
          )}
          <button className="button clear continue" onClick={onClose}>
            {intl.formatMessage(trads.continue)}
          </button>
          {quantity > 0 && (
            <ShippingStatus
              className="shipping-status"
              shopOptions={options}
              displayBanner={false}
            />
          )}
          <CrossSell itemSkus={itemSkus} products={options.crossSell} onAdd={apiAddToCart} />
        </Cart>
      </SlidingPanel>
    </Wrapper>
  );
};

export default CartPanel;
