import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 30px 0;

  form {
    display: flex;
    column-gap: 15px;
    background-color: #f3f3f3;
    margin-left: calc(var(--cart-panel-margin-inline) * -1);
    margin-right: calc(var(--cart-panel-margin-inline) * -1);
    margin-top: 10px;
    padding: 10px var(--cart-panel-margin-inline);

    input {
      flex: 1;
      margin: 0;
    }

    button.block {
      display: flex;
      align-items: center;
      width: max-content;
      font-size: 14px;
      color: #FFF;
      padding: 5px 8px;

      svg {
        margin: 0;
      }

      &:after {
        display: none;
      }
    }
  }
`;

export const Title = styled.div`
  font-size: 14px;

  strong {
    color: #B72B1C;
    font-weight: bold;
  }
`;