import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  position: relative;
  border-top: 1px solid #8A8A8A;
  padding-top: 20px;

  &:last-child {
    border-bottom: 1px solid #8A8A8A;
    padding-bottom: 20px;
  }

  margin: 25px 0;

  .item {
    display: flex;
  }

  .image {
    background-color: #fafafa;
    width: 110px;
    height: 110px;
    flex: 0 0 auto;
    border-radius: 5px;


    ${device.tablet} {
      width: 110px;
      height: 110px;
    }
    .gatsby-image-wrapper {
      width: 100%!important;
      height: 100%!important;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${device.tablet} {
      padding-right: 10px;
    }

    .bottom {
      display: flex;
      align-items: flex-end;

      > * {
        flex: 1;
      }
    }
  }
  .promo {
    font-size: 12px;
    font-weight: 500;
    margin:3px 0;
  }

  .options {
    font-size: 12px;
    color: #8b8c91;
    letter-spacing: 0.1px;

    strong {
      font-weight: 500;
    }

    .option {
      padding: 3px 0;
    }
  }

  h4 {
    margin: 0 15px 5px 0;
    font-size: 15px;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .dashed {
      font-size: 15px;
      margin-right: 0;
    }
  }

  .remove {
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    transform: scaleY(0.8);

    &:hover {
      font-weight: 500;
    }
  }

  .success {
    flex: 1 1 100%;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: #00632b;
    padding-top: 15px;
  }
`;
