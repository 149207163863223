/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  viewMore: 'ecom.widgets.view-more',
  outOfStock: 'ecom.widgets.out-of-stock',
  inStock: 'ecom.widgets.in-stock',
  recommendation: 'ecom.widgets.recommendation',
  garantee: 'ecom.widgets.garantee',
  freeShipping: 'ecom.widgets.free-shipping',
  exchange: 'ecom.widgets.exchange',
  official: 'ecom.widgets.official',
  secure: 'ecom.widgets.secure',
};
