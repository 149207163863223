import React from 'react';
import Img from 'gatsby-image';

import Price from '../eshop/Price';
import Quantity from '../eshop/Quantity';

import { ShopifyFixed } from '../../utils';
import { Wrapper } from './CartItem.styles';
import { useLocale } from '../../services/i18n/useLocale';
import trads from './trads';
import { JASOReformatViscosity } from '../../utils/jaso';

const CartItem = ({
  item,
  removeLineItem,
  updateLineItem,
  loading = false,
  showAdded = false
}) => {
  const intl = useLocale();

  if (!item?.variant) {
    return null;
  }
  const { variant, discountAllocations = [] } = item;
  const { image } = variant;

  const thumbnailFixed = ShopifyFixed(
    image.src,
    { width: 160 },
    { width: 1, height: 1 }
  );

  let price = (parseFloat(variant.price?.amount, 10) || 0) * item.quantity;
  let discountText = [];

  const discount = discountAllocations.reduce((res, line) => {
    const current = parseFloat(line?.allocatedAmount?.amount, 10) || 0;

    if (line?.discountApplication?.title) {
      discountText.push(line?.discountApplication?.title);
    }
    return res + current;
  }, 0);


  const compareAtPrice = price;
  price = price - discount;

  // console.log('cart item', item, price, discountText);
  return (
    <Wrapper>
      <div className="item">
        <div className="image">
          {image ? (
            <Img
              fixed={thumbnailFixed}
              alt={image.altText}
              imgStyle={{
                objectFit: 'contain'
              }}
            />
          ) : null}
        </div>
        <div className="text">
          <div className="top">
            <h4>{item.title}</h4>
            <div className="options">
              {variant.selectedOptions
                .filter(o => o.name !== 'Title')
                .map((opt, index) => (
                  <div
                    key={index}
                    className="option"
                  >
                    {opt.name}: <strong>{JASOReformatViscosity(opt.value)}</strong>
                  </div>
                ))}
            </div>
            <div className="promo">
              {discountText.map(line => (
                <div key={line}>{line}</div>
              ))}
            </div>
            {/* <button
            className="clear remove"
            onClick={() => removeLineItem(item)}
          >
            X
          </button> */}
          </div>
          <div className="bottom">
            <Quantity
              quantity={item.quantity}
              onUpdate={(quantity) => {
                updateLineItem(item, quantity);
              }}
              disabled={loading}
              small
            />
            <Price
              price={price}
              originalPrice={compareAtPrice}
            />
          </div>

        </div>
      </div>
      {!!showAdded && (
        <div className="success">
          <span>{intl.formatMessage(trads.itemAdded)}</span>
        </div>
      )}
    </Wrapper>
  );
};

export default CartItem;
