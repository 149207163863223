import React from 'react';
import joursFeries from '@socialgouv/jours-feries';
import parseMS from 'parse-ms';

import { Icon } from '../icons';
import GreenCheckMark from '../../icons/GreenCheckMark.svg';
import RedMark from '../../icons/Close.svg';

import {
  getLocaleSettings,
  isToday,
  isWeekend,
  useBreakpoint,
  parseDate,
} from '../../utils';
import useCountDown from '../../utils/useCountDown';

import { Wrapper } from './ShippingStatus.styles';
import { useLocale } from '../../services/i18n/useLocale';
import trads from '../../screens/eshop/trads';

const Line = ({ children }) => (
  <li>
    <Icon src={GreenCheckMark} height={10} width={12} />
    <div>{children}</div>
  </li>
);

const NoGoodLine = ({ children }) => (
  <li>
    <Icon src={RedMark} height={10} width={12} style={{
      color: "#c80f05"
    }} />
    <div>{children}</div>
  </li>
);

const CountDown = ({ date }) => {
  const timer = useCountDown(date);
  const { days, hours, minutes, seconds } = parseMS(timer);
  const parts = [];

  days && parts.push(`${days}J`);
  hours && parts.push(`${hours}h`);
  minutes && parts.push(`${minutes}min`);
  parts.push(`${seconds}s`);

  return (
    <span className="count-down" suppressHydrationWarning>
      {typeof window !== 'undefined' ? parts.join(' ') : '...'}
    </span>
  );
};

const ShippingStatus = ({
  variant,
  shopOptions,
  className,
  isProductPage = false,
  displayBanner = true,
}) => {
  const intl = useLocale();
  const device = useBreakpoint();
  const settings = getLocaleSettings(intl.locale);

  if (!settings.shop) {
    return null;
  }

  const today = new Date();
  let holidays = joursFeries(new Date().getFullYear());
  holidays = Object.keys(holidays)
    .map((key) => holidays[key])
    .sort((a, b) => a - b);
  const isHoliday = !!holidays.find((d) => isToday(d));
  const isWeekdays = !isWeekend();
  const isPossibleToday =
    new Date().getHours() < (shopOptions.shipLimitHour || 0);
  const showShipToday = isPossibleToday && !isHoliday && isWeekdays;
  const timeLimit = new Date();
  timeLimit.setHours(shopOptions.shipLimitHour || 0);
  timeLimit.setMinutes(0);
  timeLimit.setMinutes(0);

  const holidaysStart = parseDate(shopOptions.holidaysStart);
  const holidaysEnd = parseDate(shopOptions.holidaysEnd);

  const isBlocked =
    !!holidaysStart &&
    !!holidaysEnd &&
    today > holidaysStart &&
    today < holidaysEnd;



  return (
    <Wrapper className={className}>
      {isProductPage ?
        (variant?.availableForSale ? <Line>{shopOptions.stockLabel}</Line> : <NoGoodLine>{intl.formatMessage(trads.outOfStock)}</NoGoodLine>)
        : null
      }
      {!isBlocked &&
        !!variant?.availableForSale &&
        device !== 'ssr' &&
        showShipToday &&
        shopOptions?.shipDetails && (
          <Line>
            {`${shopOptions?.shipDetails}`.split('%').map((part, idx) => {
              if (part !== 'timer') {
                return part;
              }

              return <CountDown key={idx} date={timeLimit} />;
            })}
          </Line>
        )}
      {isBlocked && !!variant?.availableForSale && !!shopOptions.holidaysMessage && (
        <Line>{shopOptions.holidaysMessage}</Line>
      )}
      {!!(displayBanner && !!variant?.availableForSale && shopOptions?.banner) && <Line>{shopOptions.banner}</Line>}
    </Wrapper>
  );
};

export default ShippingStatus;
