import { useEffect, useState } from 'react';


const parseDate = (date) => {
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return new Date(parsed);
  }

  return new Date(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
};

function useCountDown(date, options) {
  const { intervalTime = 1000, now = () => Date.now() } = options || {};
  const [timeLeft, setTimeLeft] = useState(
    () => parseDate(date).getTime() - now()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((current) => {
        if (current <= 0) {
          clearInterval(interval);

          return 0;
        }

        return current - intervalTime;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [intervalTime]);

  return timeLeft;
}

export default useCountDown;
