import React from 'react';
import cs from 'classnames';

import {
  Wrapper
} from './Quantity.style';

const Quantity = ({
  quantity,
  onUpdate,
  disabled = false,
  small = false,
  allowDelete = false,
}) => {
  const incQuantity = React.useCallback(() => {
    onUpdate(quantity + 1);
  }, [onUpdate, quantity]);

  const decQuantity = React.useCallback(() => {
    onUpdate(quantity - 1);
  }, [onUpdate, quantity]);

  return (
    <Wrapper
      className={cs({
        small
      })}
    >
      <button
        onClick={decQuantity}
      // disabled={(!allowDelete && quantity <= 1) || disabled}
      >
        -
      </button>
      <span>{quantity}</span>
      <button
        onClick={incQuantity}
        disabled={disabled}
      >
        +
      </button>
    </Wrapper>
  );
};

export default Quantity;
