import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Label = styled.p`
  font-size: 13px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const ProgressGlobal = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 30px;
`;

export const ProgressBarWrapper = styled.div`
  position: relative;
  background: #d9d9d9;
  border-radius: 30px;
  height: 8px;
  margin: 5px 0;
  overflow: hidden;
`;

export const ProgressBarInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #c20700;
  border-radius: 30px;
  transform: translate3d(-100%, 0, 0);
  transform-origin: left;
  backface-visibility: hidden;

  transition: transform 1200ms ease-in-out, background-color 1200ms ease-in-out;
`;

export const CartBubble = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -20px;
  background: #d9d9d9;
  backface-visibility: hidden;

  transition: background-color 1200ms ease-in-out;

  &.is-active {
    background-color: #c20700;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    height: 60%;
    margin: 0;
    margin-top: 20%;
    margin-left: 20%;
    color: #fff;
  }

  .price {
    position: absolute;
    top: 27px;
    font-size: 14px;
    width: max-content;
    font-weight: 500;
    left: 50%;
    transform: translateX(-50%);
  }

  .label {
    position: absolute;
    bottom: 27px;
    font-size: 13px;
    width: max-content;
    font-weight: 500;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80px;
    text-align: center;
  }
`;

export const ProgressHint = styled.div`
  background-color: #f3f3f3;
  margin-left: calc(var(--cart-panel-margin-inline) * -1);
  margin-right: calc(var(--cart-panel-margin-inline) * -1);
  padding-block: 10px;
  padding-inline: var(--cart-panel-margin-inline);
  font-size: 14px;
`;
