import classNames from 'classnames';
import React from 'react';
import { priceFormat, splitLines } from '../../utils';
import { formatPriceWithLazyDecimals } from '../eshop/Price';

import CheckmarkIcon from '../../icons/cart-checkmark.svg';
import { Icon } from '../icons';

import {
  Wrapper,
  ProgressBarWrapper,
  ProgressBarInner,
  CartBubble,
  ProgressGlobal,
  ProgressHint
} from './CartProgress.styles';

const formatText = (text) => {
  return splitLines(
    `${text || ''}`.replace(/\*\*(.*?)\*\*/gm, '<strong>$1</strong>')
  ).join('<br />');
};

// const points = [
//   {
//     label: 'Tirage au sort',
//     amount: 25,
//   },
//   {
//     label: 'Livraison gratuite',
//     amount: 39.9,
//   },
//   {
//     label: '1 Motowash offert',
//     amount: 60,
//   },
// ];

const CartProgress = ({
  // stepPrice,
  currentPrice,
  // incentiveText,
  // congratsText,
  // currency,
  incentiveSteps = [],
}) => {
  let steps = incentiveSteps?.filter((step) => step.title && step.amount) || [];
  // const remaining = goalPrice - currentPrice;
  const goalPrice = steps?.slice()?.pop()?.amount;
  const percent = Math.max(currentPrice / goalPrice, 0);

  if (!steps?.length || !goalPrice) {
    return null;
  }

  // const remainingText = formatText(incentiveText).replace(
  //   '%price%',
  //   priceFormat(remaining, currency)
  // );
  // const congratsTextformatted = formatText(congratsText);

  const currentPercentage = Math.min(Math.round(percent * 100), 100);

  steps = steps.map((point) => {
    const currentPercentage = Math.max(point.amount / goalPrice, 0);
    const isActive = currentPercentage <= percent;

    return {
      ...point,
      currentPercentage,
      isActive
    };
  });

  const lastActive = steps?.slice()?.reverse()?.find(step => step.isActive);

  return (
    <Wrapper>
      {/* <Label dangerouslySetInnerHTML={{ __html: remaining > 0 ? remainingText : congratsTextformatted }} /> */}
      <ProgressGlobal>
        <ProgressBarWrapper>
          <ProgressBarInner
            style={{
              transform: `translate3d(-${100 - currentPercentage}%, 0, 0)`,
            }}
          />
        </ProgressBarWrapper>
        {steps?.map((point, idx) => {
          const currentPercentage = point.currentPercentage;
          const isActive = point.isActive;
          return (
            <CartBubble
              key={idx}
              className={classNames({
                'is-active': isActive,
              })}
              style={{
                left: `${Math.min(Math.round(currentPercentage * 100), 100)}%`,
              }}
            >
              {!!isActive && <Icon src={CheckmarkIcon} />}
              <div className="label">{point.title}</div>
              <div className="price">
                {formatPriceWithLazyDecimals(point.amount)}
              </div>
            </CartBubble>
          );
        })}

      </ProgressGlobal>
      {!!lastActive?.description && (
        <ProgressHint>{lastActive?.description}</ProgressHint>
      )}
    </Wrapper>
  );
};

export default CartProgress;
