import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  .sliding-panel-container {
    &.active {
      background-color: rgba(17, 17, 17, 0.65);
    }
  }

  /* .panel {
    height: calc(100vh - calc(100vh - 100%))!important;
  } */

  .panel {
    overflow: hidden!important;
    -webkit-overflow-scrolling: touch;
  }
`;

export const LoaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid #111;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #111 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Cart = styled.div`
  --cart-panel-margin-inline: 20px;
  padding: 20px var(--cart-panel-margin-inline);
  color: #000;
  max-width: 100%;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  overflow: auto;
  background-color: #fff;

  @media ${device.tablet} {
    --cart-panel-margin-inline: 30px;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    strong {
      font-weight: bold;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      margin-left: 5px;
    }

    ${LoaderWrapper} {
      margin-left: 10px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      cursor: pointer;
      svg {
        margin: 0;
      }
    }
  }

  

  .empty {
    margin: 30px 0;
    padding: 30px 0;
    text-align: center;
    border: 1px solid #8A8A8A;
    border-left: 0;
    border-right: 0;
  }

  .shipping-status {
    margin: 30px 0;
    padding-left: 0;
  }

  .banner {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    background-color: #fafafa;
    padding: 10px;
    margin: 5px 0;
  }

  .recap {
    margin-top: 30px;
    margin-right: 10px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;

      .label {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .value {
        .price {
          padding-top: 0;
        }

        &.big {
          .price {
            font-size: 22px;
          }
        }
      }
    }
  }

  .button {
    display: block;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    &.validate {
      background-color: #c80f05;
      font-weight: 500;
      font-style: normal;
      font-size: 17px;
      border-radius: 30px;
      padding: 15px 20px;
      transition: background-color 300ms;

      &:hover {
        background-color: #b30e05;
      }
    }

    &.continue {
      background-color: #f7f7f7;
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      border-radius: 30px;
      padding: 15px 20px;
      margin-top: 20px;
      transition: background-color 300ms;

      &:hover {
        background-color: #E9E9E9;
      }
    }
  }

  .secure {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7e82;
    font-size: 12px;

    .icon {
      margin-right: 5px;
      opacity: .6;
    }
  }

  
`;

