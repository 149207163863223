/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  configuratorTitle: 'ecom.configurator.title',
  faqTitle: 'ecom.faq.title',
  noProducts: 'ecom.no-products',
  readReviews: 'ecom.reviews-read',
  viewMore: 'ecom.view-more',
  search: 'ecom.search',
  sortNatural: 'ecom.sort-natural',
  sortPrice: 'ecom.sort-price',
  sortPriceDown: 'ecom.sort-price-down',
  madeInFrance: 'ecom.made-in-France',
  buy: 'ecom.buy',
  addToCart: 'ecom.add-to-cart',
  outOfStock: 'ecom.out-of-stock',
  productInformations: 'ecom.product-informations',
  productWarnings: 'ecom.product-warnings',
  productDescriptionTitle: 'ecom.product-description-title',
  productTableTitle: 'ecom.product-table-title',
  productTableCurrent: 'ecom.product-table-current',
  gotoProductRange: 'ecom.goto-product-range',
  feedbackTitle: 'ecom.feedback-title',
  feedbackCommentsTab: 'ecom.feedback-comments-tab',
  feedbackVideosTab: 'ecom.feedback-videos-tab',
  feedbackVideosNothing: 'ecom.feedback-videos-nothing',
  dealsTitle: 'deals.title',
  dealsSave: 'deals.save',
  dealsCTA: 'deals.cta'
};
