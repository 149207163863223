import React from 'react';
import Img from 'gatsby-image';
import { Wrapper } from './CrossSell.styles';
import { nl2br, ShopifyFixed } from '../../utils';
import Price from '../eshop/Price';
import { useLocale } from '../../services/i18n/useLocale';
import trads from './trads';

const CrossSell = ({ itemSkus = [], products = [], onAdd }) => {
  const intl = useLocale();

  const crossSell = products?.find((cs) => itemSkus.indexOf(cs?.product?.defaultVariant?.sku) === -1);
  const product = crossSell?.product;

  if (!crossSell || !product) {
    return null;
  }

  const { thumbnail, defaultVariant: variant } = product;

  const thumbnailFixed = ShopifyFixed(
    thumbnail.originalSrc,
    { width: 160 },
    { width: 1, height: 1 }
  );

  return (
    <Wrapper>
      {!!crossSell.title && (
        <h3 className="section-title">{crossSell.title}</h3>
      )}
      <div className="item">
        <div className="image">
          {!!thumbnail ? (
            <Img
              fixed={thumbnailFixed}
              alt={product.title}
              imgStyle={{
                objectFit: 'contain'
              }}
            />
          ) : null}
        </div>
        <div className="text">
          <div className="top">
            <div className="left-col">
              <h4>{product.title}</h4>
              <div className="category">
                {nl2br(product.productType?.replaceAll(' +', ' \n+'))}
              </div>
            </div>
            <div className="right-col">
              <Price
                price={variant.price}
                originalPrice={variant.compareAtPrice}
              />
            </div>
          </div>
          <div className="bottom">
            <div
              className="simple-link block buy"
              onClick={() => {
                onAdd?.(variant.shopifyId, 1);
              }}
            >
              {intl.formatMessage(trads.addToCart)}
            </div>
          </div>

        </div>
      </div>
    </Wrapper>
  );
};

export default CrossSell;