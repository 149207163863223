import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding-left: 0px;

  @media ${device.laptop} {
    padding-left: 30px;
  }

  li {
    display: flex;
    margin: 5px 0;
    font-size: 14px;
  }
  .icon {
    margin-top: 3px;
    margin-right: 10px;
    flex: 0 0 12px;
  }

  .count-down {
    font-weight: bold;
  }
`;