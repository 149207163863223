import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  position: relative;
  padding: 20px var(--cart-panel-margin-inline);
  background: #DBE3F0;

  margin: 25px calc(var(--cart-panel-margin-inline) * -1);

  .section-title {
    font-size: 15px;
    color: #C20700;
    font-weight: 700;
    text-transform: none;
    margin-top: 0;
  }

  .item {
    display: flex;
  }

  .image {
    background-color: #fafafa;
    width: 110px;
    height: 110px;
    flex: 0 0 auto;
    border-radius: 5px;

    ${device.tablet} {
      width: 110px;
      height: 110px;
    }
    .gatsby-image-wrapper {
      width: 100%!important;
      height: 100%!important;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    flex: 1;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }

    .left-col {
      flex: 1;
    }

    .right-col {
      margin-left: 15px;

      & > * {
        padding-top: 0;
      }
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: 10px;

      .simple-link {
        color: #FFF;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .category {
    font-size: 13px;
  }

  h4 {
    margin: 0 0 5px 0;
    font-size: 15px;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .dashed {
      font-size: 15px;
      margin-right: 0;
    }
  }

`;
