import React from 'react';
import NewsletterForm from '../common/NewsletterForm';
import { Wrapper, Title } from './CartNewsletter.style';
import { getLocaleSettings, priceFormat } from '../../utils';
import { useLocale } from '../../services/i18n/useLocale';
import trads from './trads';
import SendIcon from '../../icons/send.svg';
import { Icon } from '../icons';

const CartNewsletter = ({ currentPrice = 0, currency }) => {
  const intl = useLocale();
  const promo = currentPrice * 0.1;
  const title = intl.formatMessage(trads.newsletterPromo, {
    price: priceFormat(promo, currency),
  });
  const settings = getLocaleSettings(intl.locale);

  if (!settings.mailchimpId) {
    return null;
  }


  return (
    <Wrapper>
      <Title>{title}</Title>
      <NewsletterForm
        submitIcon={<Icon src={SendIcon} width={24} />}
      // addSamouraiTag
      />
    </Wrapper>
  );
};

export default CartNewsletter;
