import React from 'react';
import cs from 'classnames';
import styled from 'styled-components';
import { useLocale } from '../../services/i18n/useLocale';

const currencyFormatter = new Intl.NumberFormat('fr', {
  style: 'currency',
  currency: 'EUR',
});

const currencyFormatterLazy = new Intl.NumberFormat('fr', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export const formatPrice = (rawPrice, quantity = 1) => {
  const price = parseFloat(rawPrice, 10);

  if (isNaN(price)) {
    return '-';
  }

  return currencyFormatter.format(price * quantity).replace(' €', '€');
};

export const formatPriceWithLazyDecimals = (rawPrice, quantity = 1) => {
  const price = parseFloat(rawPrice, 10);
  const decimals = rawPrice - Math.floor(rawPrice);

  if (isNaN(price)) {
    return '-';
  }

  if (decimals === 0) {
    return currencyFormatterLazy.format(price * quantity).replace(' €', '€');
  }

  return currencyFormatter.format(price * quantity).replace(' €', '€');
};

const Wrapper = styled.div`
  padding-top: 10px;
  margin-top: auto;
  font-size: 17px;
  display: flex;
  justify-content: flex-end;

  &.align-left {
    justify-content: flex-start;
  }

  .dashed {
    color: #c80f05;
    text-decoration: line-through;
    margin-right: 15px;
  }

  small {
    font-size: 13px;
    margin-right: 5px;
    font-weight: normal;
    font-style: normal;
    color: #6f7175;
  }

  &.display-from {
    justify-content: space-between;
    align-items: center;
  }

  .price-value {
    font-family: 'Montserrat';
    font-weight: bold;
  }
`;

const Price = ({
  price,
  originalPrice,
  quantity = 1,
  displayFrom = false,
  stacked = false,
  alignLeft = false,
}) => {
  const onSale = originalPrice && price !== originalPrice;
  const intl = useLocale();
  return (
    <Wrapper
      className={cs('price', {
        'on-sale': onSale,
        'display-from': displayFrom,
        'align-left': alignLeft,
      })}
    >
      {displayFrom && (
        <small>{intl.formatMessage('ecom.from-price')}&nbsp;</small>
      )}
      {onSale ? (
        <span className="dashed">{formatPrice(originalPrice, quantity)}</span>
      ) : null}
      <span className="price-value">
        {price === 0
          ? intl.formatMessage('ecom.free-price')
          : formatPrice(price, quantity)}
      </span>
    </Wrapper>
  );
};

export default Price;
